import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseService} from './base.service';
import {
  DealerListRequest,
  DealerOrderReportRequest,
  DealerRequest,
  FileUploadRequest,
  OtpRequest,
  PurchaseServiceRequest,
  Salesperson
} from '../model/dealer';
import {DealerPolicyRequest, ProductDefinitionRequest} from '../model/Request';
import {environment} from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DealerService extends BaseService {


  private dealerBasePath = '/web/v1/dealer/';
  private policyBasePath = '/api/policy-documents/';
  protected _baseUrl: string = environment._baseUrl;
  constructor(private http: HttpClient) {
    super(http);
  }


  updateDealer(request: DealerRequest): Observable<any> {
    return this.post<any, DealerRequest>(this.dealerBasePath + 'order', request);
  }

  createDealer(request: DealerRequest): Observable<any> {
    return this.post<any, DealerRequest>(this.dealerBasePath + 'create/dealer', request);
  }

  updateDealerList(request: DealerListRequest): Observable<any> {
    return this.post<any, DealerListRequest>(this.dealerBasePath + 'update/dealer', request);
  }
  getOtp(phone: string): Observable<any>  {
    return this.get(this.dealerBasePath + 'otp/' + phone);
  }
  getOtpWithLinks(request: OtpRequest): Observable<any>  {
    return this.post<any,OtpRequest>(this.dealerBasePath + 'otp/withlink' ,request);
  }
  sendOtp(request: OtpRequest): Observable<any> {
    return this.post<any,OtpRequest>(this.dealerBasePath + 'otp',request);
  }
  getImeiInfo(imei: string) : Observable<any>{
    return this.get(this.dealerBasePath + 'imei/' + imei);
  }
  getScreenProperties() : Observable<any>{
    return this.get(this.dealerBasePath + 'screenProperties');
  }
  calculatePremium(request: DealerRequest): Observable<any> {
    return this.post<any, DealerRequest>(this.dealerBasePath + 'order/prime-calculation', request);
  }
  createOffer(request: DealerRequest): Observable<any> {
    return this.post<any, DealerRequest>(this.dealerBasePath + 'order/offer', request);
  }
  purchase(request: PurchaseServiceRequest) {
    return this.post<any, PurchaseServiceRequest>(this.dealerBasePath + 'order/purchase', request);
  }
  checkKps(kpsRequest: any): Observable<any> {
    return this.post<any, any>(this.dealerBasePath + 'identityNumber', kpsRequest);
  }
  sendDocument(request: { email: any; refId: number; }): Observable<any>{
    return this.post(this.dealerBasePath + 'policy/document/mail', request);
  }
  sendPolicyEmail( email: string, policyRef: string ): Observable<any> {
    return this.post(this.policyBasePath + 'email', {  email, policyRef});
}
  sendPolicySms(policyRef: string): Observable<any> {
    return this.post(this.policyBasePath + 'sms/' + policyRef, null);
  }
  getPolicyDocument( token: string, identityNumberNbirthDate: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const GetRequest = { identityNumberNbirthDate };
    return this.http.post( this._baseUrl + this.policyBasePath + 'docs/' + token, GetRequest, { headers, responseType: 'arraybuffer' });
  }
  getDocs(refId): Observable<any>  {
    return this.get(this.dealerBasePath + 'document/reference/' + refId);
  }
  getDoc(refId): Observable<any>  {
    return this.get(this.dealerBasePath + 'document/' + refId);
  }
  deleteDoc(refId): Observable<any>  {
    return this.delete(this.dealerBasePath + 'document/' + refId);
  }
  saveDoc(request: FileUploadRequest){
    return this.post(this.dealerBasePath + 'document/save' , request);
  }

  getSalespersonList(): Observable<any> {
    return this.get( this.dealerBasePath + 'salesperson/list' );
  }

  saveSalesperson(request: Salesperson ): Observable<any> {
    return this.post( this.dealerBasePath + 'salesperson', request );
  }

  getDealerList(): Observable<any> {
    return this.get( this.dealerBasePath + 'list');
  }

  getDealerListWithId(dealerCode: string): Observable<any> {
    return this.get( this.dealerBasePath + 'list/' + dealerCode);
  }

  getDealerReportList( request: DealerOrderReportRequest ): Observable<any> {
    return this.post( this.dealerBasePath + 'report/list' , request );
  }

  getDealerReportExcel( request: DealerOrderReportRequest ): Observable<any> {
    return this.post( this.dealerBasePath + 'report/file' , request );
  }

  getDealerListReportExcel( ): Observable<any> {
    return this.post( this.dealerBasePath + 'report/dealer' , null );
  }

  getSumDealerReport( request: DealerOrderReportRequest ): Observable<any> {
    return this.post( this.dealerBasePath + 'report/total' , request );
  }

  getMarcaList( category: number ): Observable<any> {
    return this.get( this.dealerBasePath + 'salesproductlist/' + category );
  }

  getModelList( category: number, marca: String ): Observable<any> {
    return this.get( this.dealerBasePath + 'salesproductlist/' + category + '/' + marca );
  }

  dealerPolicySave(request: DealerPolicyRequest): Observable<any> {
    return this.post(this.dealerBasePath + 'external/policy/', request)
  }

  dealerPolicyDelete(id): Observable<any> {
    return this.delete(this.dealerBasePath + 'external/policy/' + id)
  }

  getDealerPolicyList(): Observable<any> {
    return this.get(this.dealerBasePath + 'external/policy/list')
  }

  productDefinitionSave(request: ProductDefinitionRequest): Observable<any> {
    return this.post(this.dealerBasePath + 'salesproduct', request)
  }

  productDefinitionDelete(id): Observable<any> {
    return this.delete(this.dealerBasePath + 'salesproduct/' + id)
  }

  getProductDefinitionList(pageNumber, text): Observable<any> {
    return this.get(this.dealerBasePath + 'salesproduct/list' + '?page=' + pageNumber + '&text=' + (text ? text : ''))
  }

  // Dealer User Area ↓
  getUserById(id): Observable<any> {
    return this.get(this.dealerBasePath + 'user/' + id);
  }

  refreshPassword(dealerId): Observable<any> {
    return this.put(this.dealerBasePath + 'reset/password?userId=' + dealerId, null);
  }

  createUser(request: DealerListRequest): Observable<any> {
    return this.post<any, DealerListRequest>(this.dealerBasePath + 'user/create', request);
  }

  deleteUser(id): Observable<any> {
    return this.delete(this.dealerBasePath + 'user/delete/' + id);
  }
  // Dealer User Area ↑

}
