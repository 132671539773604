export enum PaginatorVariables{
  itemsPerPage = 'Satır Sayısı',
  nextPageLabel = 'Sonraki',
  previousPageLabel = 'Önceki',
  lastPageLabel = 'Son Sayfa',
  firstPageLabel = 'İlk Sayfa'
}

export enum Credentials {
  PASSWORD = 'password'
}

export enum CrudOperations {
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
  READONLY = 'READONLY'
}

export enum UserGroups{
  admin = 'admin',
  group1 = 'grup1',
  group2 = 'grup2',
  bayi = 'bayi',
  bayireporter = 'bayireporter',
  test = 'test' ,
  changer = 'changer'
}
