<div class="main-panel">
    <div class="main-content" style="margin-left: 200px;">
      <!-- login olayi hallolunca yukaridaki yerine asagidaki devrede olacak -->
    <!--<div class="main-content" [ngStyle]="{'margin-left': currentUser == null ? '0px': '250px'}">-->

      <div class="content-overlay"></div>
      <div class="content-wrapper">
          <router-outlet></router-outlet>
        </div>
    </div>
    </div>

<app-loader></app-loader>
