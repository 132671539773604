import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { LoginResponse } from '../../pages/content-pages/_model/Login';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from './loader.service';
import { UtilityService } from './utility.service';
import { ToasterPosition } from '../constants/ToasterPosition';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private router: Router,
    private authenticationService: AuthService,
    private loaderService: LoaderService,
    private utilityService: UtilityService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + `${currentUser.accessToken}`
        }
      });
    }

    // handle response
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event.hasOwnProperty('body')) {
        this.modifyBody((event as any).body);
      }
      return event;
    }, (error: any) => {
      if (error.hasOwnProperty('error')) {
        const err = error;
        if (err.hasOwnProperty('refreshToken')) {
          this.modifyBody(err);
        }
        if (error.status === 401){
          this.router.navigate(['/pages/login']);
        } else if ( error.status <= 500){
          this.loaderService.hide();
          if( err.error.message == null || err.error.message === '' ){
            this.utilityService.showWarning('Uyarı', 'Beklenmeyen bir durumla karşılaşıldı. ' +
                                              'Sistem yöneticinize danışınız', ToasterPosition.topRight);
          } else {
            this.utilityService.showWarning('Uyarı', err.error.message, ToasterPosition.topRight);
          }
        } else {
          this.loaderService.hide();
          this.utilityService.showAlert('Hata', err.message, ToasterPosition.bottomRight);
        }
      }
    }));
  }

  private modifyBody(body: any) {
    if (body.refreshToken) {
      const currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
      const modifiedCurrentUser = currentUser as LoginResponse;
      modifiedCurrentUser.refreshToken = body.refreshToken;
      localStorage.setItem('currentUser', JSON.stringify(modifiedCurrentUser));
      this.authenticationService.currentUserSubject.next(modifiedCurrentUser);
    }
  }
}
