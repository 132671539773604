import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from '@angular/core';
import { ROUTES } from './vertical-menu-routes.config';
import { HROUTES } from '../horizontal-menu/navigation-routes.config';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from '../animations/custom-animations';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';
import {RouteInfo} from './vertical-menu.metadata';
import {AuthService} from '../auth/auth.service';
import {LoginResponse} from '../../pages/content-pages/_model/Login';
import {environment} from '../../../environments/environment';
import { exit } from 'process';

@Component({
  selector: 'app-sidebar',
  templateUrl: './vertical-menu.component.html',
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: RouteInfo[];
  level = 0;
  logoUrl = '';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;

  constructor(private router:        Router,
              public translate:      TranslateService,
              private layoutService: LayoutService,
              private configService: ConfigService,
              private cdr:           ChangeDetectorRef,
              private deviceService: DeviceDetectorService,
              private authService:   AuthService) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }

  ngOnInit() {
    //TODO BACKENDDEN gelmeyecek simdilik. Ilerde yapilabilir
    
  }

  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();
    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === 'Side') {
          this.collapseSidebar = collapse;
        }
      });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
      if (this.resizeTimeout) {
          clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout((() => {
        this.innerWidth = event.target.innerWidth;
          this.loadLayout();
      }).bind(this), 500);
  }

  loadLayout() {

    if (this.config.layout.menuPosition === 'Top') { // Horizontal Menu
      if (this.innerWidth < 1200) { // Screen size < 1200
        this.menuItems = HROUTES;
      }
    } else if (this.config.layout.menuPosition === 'Side') { // Vertical Menu{
      this.menuItems = this.filterMenuItemsByCompany();
    }

    if( this.authService.getmobiluser ){
      this.logoUrl = 'assets/img/get_logo.jpg';
    }
    /*if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = 'assets/img/logo.png';
    }*/

    if(this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    } else {
      this.collapseSidebar = false;
    }

    //this.menuItems = this.filterMenuItemsByCompany();
  }

  toggleSidebar() {
    const conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent('HTMLEvents');
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    } else {
      this.perfectScrollbarEnable = true;
    }
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  /**
   * loginResponse nesnesindeki groups altinda bulunana company grubu bulunur
   * ve bu company ile ayni isimde olan menu gosterilir.
   * Ayrica companyName alani bos olan demek herkes icin acik demek ve gosterilir
   */
  filterMenuItemsByCompany(){
    const currentUser = this.authService.currentUserValue;
    //filter main routes
    const routeInfoList = new Array<RouteInfo>();
    ROUTES.forEach(menuItem => {
      if(menuItem.path.indexOf('accessToken')>0)
      {
        menuItem.path = menuItem.path.replace('accessToken',currentUser.accessToken);
      }
      menuItem.groups.forEach(element => {
        if(this.authService.currentUserValue.groups.some(x=>x == element))
        {
          if(!routeInfoList.some(x=>x.id == menuItem.id)){
            let cloneitem = Object.create(menuItem);
          routeInfoList.push(cloneitem);
          }
        }
      });
    });
    
    routeInfoList.forEach(element => {
      const submenuList = new Array<RouteInfo>();
        element.submenu.forEach(item => {
          item.groups.forEach(element => {
            if(this.authService.currentUserValue.groups.some(x=>x == element))
            {
              if(!submenuList.some(x=>x.id == item.id)){
                let cloneitem = Object.create(item);
                submenuList.push(cloneitem);
              }
            }
          });
        })
        element.submenu = submenuList;
    });
    const allRoles = new Map(Object.keys(currentUser.roles).map(key => [key, currentUser.roles[key]]))
    const clientRoles = allRoles.get(environment.keycloak_client_id);

    return routeInfoList;
  }

}
