import { Injectable } from '@angular/core';
import {BaseService} from '../services/base.service';
import {HttpClient} from '@angular/common/http';
import {LoginRequest, LoginResponse, User} from '../../pages/content-pages/_model/Login';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ApiResponse} from '../model/Response';
import { UserGroups } from '../constants/Enums';

@Injectable()
export class AuthService extends BaseService {

  public currentUserSubject: BehaviorSubject<LoginResponse>;
  public currentUser: Observable<LoginResponse>;

  constructor(private http: HttpClient) {
    super(http);
    this.currentUserSubject = new BehaviorSubject<LoginResponse>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  signupUser(email: string, password: string) {
  }

  signinUser(loginRequest: LoginRequest): Observable<ApiResponse> {
    return this.post<ApiResponse, LoginRequest>('/auth/web/login', loginRequest);
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  public assignCurrentUser(currentUserData: LoginResponse){
    this.currentUserSubject.next(currentUserData);
  }

  public get currentUserValue(): LoginResponse {
    return this.currentUserSubject.value;
  }

  public getUserClientRoles(): string[] {
    return this.currentUserValue.roles.get(environment.keycloak_client_id);
  }

  public getUserCompanyRoles(): string[] {
    return this.getUserClientRoles().filter(a => a.indexOf(this.currentUserValue.companyName) >= 0);
  }
  public checkUserGroup(group: UserGroups) : Boolean{
    return this.currentUserValue.groups.includes('/' + group);
  }
  public get group1() : Boolean {
    return this.checkUserGroup(UserGroups.group1);
  }
  public get group2() : Boolean {
    return this.checkUserGroup(UserGroups.group2);
  }
  public get bayiGroup() : Boolean {
    return this.checkUserGroup(UserGroups.bayi) || this.checkUserGroup(UserGroups.bayireporter);
  }
  public get admin() : Boolean {
    return this.checkUserGroup(UserGroups.admin);
  }
  public get test(): Boolean {
    return this.checkUserGroup(UserGroups.test);
  }
  public get changer(): Boolean {
    return this.checkUserGroup(UserGroups.changer);
  }
  public get getmobiluser(): Boolean {
    return this.currentUserValue.fullname.includes('GETMOBIL') ;
  }
  public get gettigerlabs(): Boolean {
    return this.currentUserValue.fullname.includes('TIGERLABS');
  }
}
