<!-- send-sms-page.component.html -->
<div class="send-sms-container">
     <h1>Döküman Görüntüle</h1>
     <form (ngSubmit)="onSubmit()">
       <div>
         <label for="input1">TC'nizin ilk 4 hanesi ile Son 2 hanesini art arda giriniz:</label>
         <input id="input1" [(ngModel)]="input1" name="input1" placeholder="XXXXXX" required pattern="\d{6}" maxlength="6">
       </div>
       <button type="submit">Görüntüle</button>
     </form>
   </div>
   <div *ngIf="pdfSrc">
     <iframe [src]="pdfSrc" width="100%"></iframe>
   </div>
   