import {Observable} from 'rxjs';
import {HttpClient, HttpEvent, HttpHeaders} from '@angular/common/http';
import {ApiException} from '../model/ApiException';
import {environment} from '../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';


export class BaseService {
  // options: RequestOptions;
  protected _baseUrl: string = environment._baseUrl;
  private _http: HttpClient;
  constructor(http: HttpClient) {
    this._http = http;
  }

  private handleError(error: HttpErrorResponse) {
    const apiError: ApiException = {
      statusCode: error.status,
      statusText: error.error.message,
      url: error.url
    };

    return Observable.throw(apiError || 'Server Error');
  }

  protected get<T>(endPoint: string): Observable<T> {
    const options = this.InitHeader();
    const url: string = this._baseUrl + endPoint;
    return this._http.get<T>(url, {headers: options});
  }
  protected delete<T>(endPoint: string): Observable<T> {
    const options = this.InitHeader();
    const url: string = this._baseUrl + endPoint;
    return this._http.delete<T>(url, {headers: options});
  }

  protected post<T, U>(endPoint: string, request: U | string): Observable<T> {
    const options = this.InitHeader();
    const url: string = this._baseUrl + endPoint;
    return this._http.post<T>(url, request, {headers: options});
  }

  protected put<T, U>(endPoint: String, request: U | string): Observable<T>{
    const options = this.InitHeader();
    const url: string = this._baseUrl + endPoint;
    return this._http.put<T>(url, request, {headers: options});
  }

  private InitHeader(): HttpHeaders {
    const headerItems = {
    };
    const headers = new HttpHeaders().append('Content-Type', 'application/json')
      .append('Accept', '*/*')
      .append('Accept-Language', 'tr-TR,tr;q=0.9,en-US;q=0.8,en;q=0.7');

    return headers;
  }
}
