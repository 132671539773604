import {RouteInfo} from './vertical-menu.metadata';

//Sidebar menu Routes and data ?? Title must be unique
export const ROUTES: RouteInfo[] = [
  // {
  //   id:1,path: '', title: 'Anasayfa', icon: 'ft-home', class: 'has-sub', badge: '',groups:['/grup1','/grup2','/admin'],
  //   badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, companyName: 'Techpo',
  //   submenu: []
  // },
  //{
  //  id:1,path: 'http://dashboard.techpo.net/login?token=accessToken&redirect=http%3A%2F%2Fdashboard.techpo.net%2Fsuperset%2Fdashboard%2F11%3Fstandalone%3Dtrue', title: 'Dashboard', icon: 'fa fa-tachometer', class: '', badge: '',
  //  badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: true, submenu: [], roles: [],groups:['/test','/grup2']
  //},
  {
    id:1,path: '/main/offers', title: 'Siparişler', icon: 'fa fa-shopping-bag', class: '', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, roles: ['ininal-operator', 'ininal-user'],groups:['/grup1','/grup2','/admin','/bayi','bayireporter', '/test'], submenu: [],
  },
  {
    path: '/main/productdefinition', title: 'Ürün Tanımı', icon: 'fa fa-sliders', class: '', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, roles: ['ininal-operator', 'ininal-user'], groups: ['/test','/productdefine'], id: 9, companyName: '',
    submenu: []
  },
  {
    path: '/main/tacdefinition', title: 'Tac Tanımı', icon: 'fa fa-sliders', class: '', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, roles: ['ininal-operator', 'ininal-user'],
    groups: ['/test'], id: 10, companyName: '', submenu: []
  },
  {
    id:2,path: '/main/lookups', title: 'Parametreler', icon: 'fa fa-sliders', class: '', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [], roles: ['ininal-operator', 'ininal-user'],groups:['/test']
  },
  {
    id:4,path: '/main/reporter', title: 'Raporlar', icon: 'fa fa-sliders', class: '', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [], roles: ['ininal-operator', 'ininal-user'],groups:['/test','/hasarreport']
  },
  /*
  {
    path: '/main/dealerlist', title: 'Bayi Listesi', icon: 'fa fa-sliders', class: '', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
    roles: ['ininal-operator', 'ininal-user'], groups: ['/test'], id: 11, companyName: '',
    submenu: []
  },
  */
  {
    id:3,path: '', title: 'Sipariş Ver', icon: 'fa fa-cart-plus', class: 'has-sub', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, roles: ['ininal-operator', 'ininal-user'],groups:['/test','/bayi','/bayireporter'], submenu: [
      {
        path: '/main/order/1', title: 'Bağlantılı Sigorta', icon: '', class: 'submenu-icon', badge: '',
        badgeClass: 'disabled', isExternalLink: true, roles: [],groups:['/test','/bayi'],id:5,companyName:'',
        submenu: []
      },
      {
        path: '/main/dealerreport', title: 'Bayi Rapor', icon: '', class: 'submenu-icon', badge: '',
        badgeClass: 'disabled', isExternalLink: false, roles: [],groups:['/bayireporter'],id:6,companyName:'',
        submenu: []
      },
      {
        path: '/main/salesperson', title: 'Satış Temsilcisi Ekle', icon: '', class: 'submenu-icon', badge: '',
        badgeClass: 'disabled', isExternalLink: false, roles: [],groups:['/bayi'],id:7,companyName:'',
        submenu: []
      },
      /*
      {
        path: '/main/dealerpolicy', title: 'Poliçe Girişi', icon: '', class: 'submenu-icon', badge: '',
        badgeClass: 'disabled', isExternalLink: false, roles: [], groups: ['/bayi'], id: 8, companyName: '',
        submenu: []
      },
      */
    ]
  }
];

/**
 * ornek:
 *  {
    path: '', title: 'Panel', icon: 'ft-home', class: 'has-sub', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, companyName: 'ininal'
    /*submenu: [
      { path: '/ininal/users', title: 'Kullanıcılar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '',
        badgeClass: '', isExternalLink: false, submenu: [], roles: ['ininal-operator', 'ininal-user'] }
    ]
    }
 */
