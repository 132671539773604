// send-sms-page.component.ts
import { Component, OnInit } from '@angular/core';
import {DealerService} from '../shared/services/dealer.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-send-sms-page',
  templateUrl: './send-sms-page.component.html',
  styleUrls: ['./send-sms-page.component.scss']
})
export class SendSmsPageComponent implements OnInit {
  pdfSrc: string | ArrayBuffer | null = null;
  input1: string = '';
  input3: string = '';
  constructor(private dealerService: DealerService,
              private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => {
      const token = fragment;
      if (token) {
        this.input3 = token;
      }
    });
  }

  onSubmit() {
    this.GetPolicyDocument( this.input3, this.input1 );
  }

  GetPolicyDocument(token: string, identityNumberNbirthDate: string) {
    this.dealerService.getPolicyDocument(token, identityNumberNbirthDate).subscribe(
      (response: ArrayBuffer) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
      },
      error => {
        console.error('Error opening the PDF', error);
      }
    );
  }
  
}
