import {Injectable} from '@angular/core';
import {ToasterPosition} from '../constants/ToasterPosition';
import {ToastrService} from 'ngx-toastr';
import { AbstractControl } from '@angular/forms';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class UtilityService {


  constructor(private toastr: ToastrService) { }

  showSuccess(title: string, message: string, positionClass: string) {
    this.toastr.success(message , title, {positionClass: positionClass});
  }

  showWarning(title: string, message: string, positionClass: string) {
    this.toastr.warning(message , title, {positionClass: positionClass});
  }

  showAlert(title: string, message: string, positionClass: string) {
    this.toastr.error(message, title, {positionClass: positionClass});
  }

  public toTurkishLowerCase(word: string) {
    let result = word;
    const letters = { İ: 'i', I: 'ı', Ş: 'ş', Ğ: 'ğ', Ü: 'ü', Ö: 'ö', Ç: 'ç' };
    result = result.replace(/(([İIŞĞÜÇÖ]))/g, letter => letters[letter]);
    return result.toLowerCase();
  }

  public toTurkishUpperCase(word: string) {
    let result = word;
    const letters = { i: 'İ', ş: 'Ş', ğ: 'Ğ', ü: 'Ü', ö: 'Ö', ç: 'Ç', ı: 'I' };
    result = result.replace(/(([iışğüçö]))/g, letter => letters[letter]);
    return result.toUpperCase();
  }

  /**
   * bu metot saat ve dakika listesi olusturur
   * minuteInterval a gore de dakika araliklari belirlenecek.
   * Ornek: minuteinterval 5 ise 5 er dakikalik liste olacak:
   * 10:00, 10:05, 10:10
   */
  generateHourMinuteList(minuteInterval: number): string[] {
    const result: string[] = [];
    for (let hour = 0; hour <= 23; hour++) {
      for (let minute = 0; minute < 60; minute++) {
        if (minute % minuteInterval === 0) {
          const modifiedHour = hour < 10 ? '0' + hour : hour; // 10 dan kucuk ise basina 0 ekliyoruz
          const modifiedMinute = minute < 10 ? '0' + minute : minute;
          result.push(modifiedHour + ':' + modifiedMinute);
        }
      }
    }
    return result;
  }

  setLoading(element,form,status) {
    var obj = $(element) ;
    if (status == true) {
      obj.data("txt", obj.html());
      obj.html('<div class="loadingio-spinner-rolling-rb7hfhs9gze"><div class="ldio-8ga000yq8nx"><div></div></div></div>');
      obj.addClass("buttonloading");
      obj.prop('disabled', true);
      //$(form + ":input").attr("disabled", true);
    } else {
      obj.html(obj.data("txt"));
      obj.removeClass("buttonloading");
      obj.prop('disabled', false);
      //$(form + ":input").attr("disabled", false);
    }
  }
  validateEmail(control: AbstractControl) {
    if(control.value == "" ||control.value == null || control.value == undefined) return null;
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!re.test(String(control.value).toLowerCase())){
        return { validEmail: true };
      }
      return null;
  }
  maskPhoneInput(current) {
    $('#phone').on('focus keydown', function(e) {
      if(this.selectionStart < 3) {
        this.selectionStart = 3;
        e.preventDefault();
      }
      if (this.value.length < 2) this.value = '0(5';
      if (e.keyCode === 8 && $('#phone').is(':focus') && $('#phone').val().length < 4) {
        e.preventDefault();
      }
      current.customerForm.controls.phone.setValue(this.value);
    });
    $('#phone').on('focusout',function(e){
    if(this.value.length<16) this.value ='0(5';
    current.customerForm.controls.phone.setValue(this.value);
   });
    $('#phone').mask("0(599) 999 99 99");
    
    $('#tckn').mask('00000000000');
  }
  check_tcno(control: AbstractControl){
    var a = control.value;
    if(a.substr(0,1)==0 || a.length!=11){
      return { validTckn: true };
    }
    var i = 9, md='', mc='', digit, mr='';
    while(digit = a.charAt(--i)){
      i%2==0 ? md += digit : mc += digit;
    }
    if(((eval(md.split('').join('+'))*7)-eval(mc.split('').join('+')))%10!=parseInt(a.substr(9,1),10)){
      return { validTckn: true };
    }
    for (var c=0;c<=9;c++){
      mr += a.charAt(c);
    }
    if(eval(mr.split('').join('+'))%10!=parseInt(a.substr(10,1),10)){
      return { validTckn: true };
    }
    return  null;
  }
  
}
