import {Router, CanLoad, Route, UrlSegment, UrlTree, ActivatedRoute} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class AuthGuardForMainModuleService implements CanLoad {

  constructor(private router: Router,
              private authenticationService: AuthService,
              private route: ActivatedRoute) { }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      return true;
    }

    this.router.navigate(['/pages/login'], { relativeTo: this.route.parent });
    return false;
  }
}
