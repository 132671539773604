import { Routes } from '@angular/router';
import { AuthGuardForMainModuleService } from '../auth/auth-guard.for.main.module.service';
import { AuthGuard } from '../auth/auth-guard.service';

// Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
    {
        path: 'main',
        loadChildren: () => import('../../main-modules/main.module').then(m => m.MainModule),
        canLoad: [AuthGuardForMainModuleService]
    }
];
