import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class LoaderService {
  isLoading = new BehaviorSubject<boolean>(null);
  show() {
      this.isLoading.next(true);
  }
  hide() {
      this.isLoading.next(false);
  }
constructor() { }

}
